import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/company/guideCategory/list',
    method: 'get',
    params
  })
}
// detail
export function getDetailAPI(id) {
  return http({
    url: '/company/guideCategory/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/company/guideCategory/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/company/guideCategory/edit',
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: '/company/guideCategory/delete',
    method: 'post',
    data: { id }
  })
}
